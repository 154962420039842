.skill-details {
    text-align:left;
    // padding-top:16px;
    // background: linear-gradient(225deg, #6D0176 -45.86%, rgba(109, 1, 118, 0) 55.93%);
    // background-color: var(--color-dark-blue);
    // color: var(--color-white);
    > .tab-container {
        // max-width:1440px;
        margin: 0px auto;
        padding:16px;
        section {
            padding:0;
        }
        .tab-list {
            white-space: nowrap;
        }
    }
    .tab-header {
        margin-bottom:16px;
        .page-nav {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap:8px;
            margin-bottom:8px;
            // color: var(--color-white);
            a {flex:none;color:inherit;height:28px;}
        }
        h2, p {
            // color:var(--color-white);
        }
    }
    .chip-list {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        gap:8px;
        margin:0;

        &.condensed {
            max-height: 35px;
            overflow:hidden;
        }
        + .link-btn {
            white-space: nowrap;
            float:right;
        }
    }
    .chip-btn {
        display:inline-block;
        border: 1px solid #dde3ee;
        border-radius: 8px;
        // background: var(--sys-placeholder-light-01, #E0E7F0);
        padding: 4px 12px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color:var(--text-default);
        margin:0;
        transition: background-color 0.15s ease-in-out;
        &:hover {
            background: #CAA0FF;
            // border-color: #CAA0FF;
          }

        svg {
            vertical-align: text-bottom;
            margin-right:4px;
        }
        &[aria-pressed=true] {
            padding-left:14px;
            background: #CAA0FF;
        }
        &.removeable {
            display:inline-flex;
            gap:8px;
            justify-content: space-between;
            align-items: center;
            padding: 4px 10px;
            svg {
                margin:0;
            }
            .button {
                
            }
            .button:hover {
                background:rgba(255,255,255,0.8);
            }
        }
    }
    @media (min-width:769px) {
        background:transparent;
        color: var(--color-black);
      > .tab-container { 
        padding: 0 var(--outside-padding);
        section {
            padding:0;
        }
    }
       .tab-header {
        // min-height:250px;
        margin-bottom:32px;
       }
    }
}

.tabs-card {
    background: var(--bg-level1);
    border: 1px solid var(--color-border);
    border-radius: var(--card-corner-default);
    padding:0;
    height:auto;

    .tab-list-container {
        background: var(--bg-level0);
        border-top-left-radius: var(--card-corner-default);
        border-top-right-radius: var(--card-corner-default);
        // padding: 16px var(--card-padding) 0 var(--card-padding);
        padding:16px 0 0 0;
        border-bottom: 1px solid var(--color-border);
        overflow-x:auto;

       
        .MuiTabs-scroller {
           
        }
        .tab-list {
            margin-bottom:0;
            padding-bottom:0;
        }
        .tab-action {
            margin: 0 16px;
            font-size:18px;
            @media (max-width:$breakpointS) {
                &.active {
                    &:after {
                        content:'';
                        height:4px;
                        width:100%;
                        background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
                        position:absolute;
                        left:0;
                        right:0;
                        bottom:0;
                    }
                    
                }
            }
            
        }
        .tab-slider {
            bottom:0;

            @media (max-width:$breakpointS) {
                display: none;
            }
        }
        @media (max-width:$breakpointS) {
            .tab-item {

            }
        }
    }
    .tab-content {
        padding: var(--card-padding);

        .card-header {
            display:flex;
            flex-direction:row;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom:24px;
            gap:8px;
        }
        .card {
            background: var(--bg-level0);
        }
    }
}