/* KEYFRAME ANIMATIONS 
-----------------------------------------------*/
@keyframes moveRightToLeft {
    0%   { right: -75px; }
    100% { right: 27px; }
}

@keyframes moveRightToLeftXL {
    0% { right: -60px; }
    100% { right: 15px;}
}

@keyframes moveRightToLeftL {
    0% { right: -60px; }
    100% { right: 3px;}
}
@keyframes moveRightToLeftM {
    0% { right: -75px; }
    100% { right: -1px;}
}

@keyframes moveRightToLeftTabletL {
    0% { right: -75px; }
    100% { right: 7px;}
}
@keyframes moveRightToLeftTabletM {
    0% { right: -75px; }
    100% { right: -1px;}
}

@keyframes moveRightToLeftMobileM {
    0%   { right: -22px; }
    100% { right: -13px; }
}

@keyframes moveRightToLeftMobileS {
    0%   { right: -22px; }
    100% { right: -9px; }
}

@keyframes spin {
    0% { transform: rotate(180deg);}
    100% { transform: rotate(0deg);}
}

@keyframes inactive-blink {
    0% {transform: scale(1, 1);}
    2% {transform: scale(1.5, 0.05);} /* for 200ms blink with 10s delay between blinks */
    2.75% { transform: scale(1, 1);} /* Switch back immediatly to open eye state and remain that way for the rest of the duration */
    100% {transform: scale(1, 1);}
}

@keyframes sleep {
    0% {transform: scale(1, 1);}
    100% {transform: scale(1, 0.2);}
}

/* (END) KEYFRAME ANIMATIONS
---------------------------------------------*/
/* Button hosting the Bot ---*/
#ai-button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: -75px;
    bottom: 40px;
    transform: rotate(180deg);
    transform-origin: center;
    animation: spin 500ms linear 100ms, moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms;
    animation-fill-mode: forwards;
    border-radius: 50%;
    cursor: pointer;
    /* bottom: 64px; */
    /* right:-64px; */
    z-index: 1000;
}

.chatbot2 {
    position: relative;
    width: 106px;
    height:106px;
    opacity: 1; /* to be animated in sleep mode */
    transition: opacity 300ms ease-in 900ms;
}

.chatbot2 .outer-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url(inactive_4x.png) no-repeat center center;
    background-size: contain;
    opacity: 1;
    transition: background-image 150ms linear, opacity 150ms linear, filter 300ms ease-in 800ms;
    width: 74px;
    height: 74px;
    background-origin: content-box;
    filter: saturate(100%); /* to be animated in sleep mode */
}

.chatbot2 .outer-ring.hover {
    background: url(active_4x.png) no-repeat center center;
    background-size: contain;
    opacity: 0;
    background-origin: content-box;
}

#ai-button:hover .chatbot2 .outer-ring,
#ai-button:hover .chatbot2 .eyes {
    opacity: 0;
}

#ai-button:hover .chatbot2 .outer-ring.hover, 
#ai-button:hover .chatbot2 .eyes.hover {
    opacity: 1;
}

#ai-button:hover .chatbot2 .outer-ring.hover {
    width: 114px;
    height:114px;
}

.eyes {
    animation-delay: 5s;
    animation: inactive-blink 10100ms infinite linear;
    transform-origin: center;
    transition: opacity 1.5s ease-in;
}

#ai-button .chatbot2 .eyes {
    position: absolute;    
    left: 50%;
    top: 50%;
    margin-top: -23px;
    margin-left: -23.4px;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.45, 1.45, 0.8, 1);
}

#ai-button .chatbot2 .eyes.hover { /* Eyes SVG on hover is solid and bright */
    opacity: 0;
    margin-top: -24px;
    margin-left: -24px;
}

#ai-button .chatbot2 .eyes.asleep { /* Eyes SVG at the end of the sleep animation is swapped to a fully closed, slightly tilted version*/
    opacity: 0;
}

/* SLEEP MODE -----------------*/
#ai-button .chatbot2.sleep {
   opacity: 0.7; 
}

#ai-button .chatbot2.sleep .outer-ring {
    filter: saturate(30%);
}

#ai-button .chatbot2.sleep .eyes {
    transition-delay: 1s;
}

#ai-button .chatbot2.sleep .eyes {
    animation: sleep 1000ms ease-in;
    animation-fill-mode: forwards;
}

#ai-button .chatbot2.sleep .eyes:not(.asleep) {
    opacity: 0;
}
#ai-button .chatbot2.sleep .eyes.asleep {
    opacity: 1;    
    transform: scale(1,1);
    animation: none;
    margin-top: -28px;
    margin-left: -24px;
}

/* removing transition delay on hover (the bot comes alive as soon as possible on hover)--*/
#ai-button:hover .chatbot2, #ai-button:hover .chatbot2 .outer-ring {
    transition: all 100ms linear;
}

/* RESPONSIVE STYLES ------------*/

@media screen and (max-width: 1440px) {
    #ai-button {
        animation-name: spin, moveRightToLeftXL;
        animation-fill-mode: forwards;
        bottom: 31px;
        bottom:24px;
    }
}
@media screen and (max-width: 1280px) {
    #ai-button {
        animation-name: spin, moveRightToLeftL;
        animation-fill-mode: forwards;
        bottom: 27px;
        bottom:24px;
    }
}

@media screen and (max-width: 1024px) {
    #ai-button {
        animation-name: spin, moveRightToLeftM;
        animation-fill-mode: forwards;
        bottom: 27px;
        bottom:24px;
    }
    
    #ai-button .chatbot2 {
        width: 90px;
        height: 90px;
    }

    #ai-button .chatbot2 .outer-ring {
        /*background: url(images/inactive_48.png) no-repeat center center;*/
        width: 58px;
        height: 58px;
    }

    #ai-button:hover .chatbot2 .outer-ring.hover {
        width: 89px;
        height: 89px;
    }

    #ai-button .chatbot2 .eyes {
        margin-top: -17px;
        margin-left: -16px;
    }
    #ai-button:hover .chatbot2 .eyes.hover {
        margin-top: -17px;
        margin-left: -16px;
    }
    #ai-button .chatbot2.sleep .eyes.asleep {
        margin-top: -18px;
        margin-left: -16px;
    }

    #ai-button .chatbot2 .eyes svg {
        width: 32px; 
        height: 32px; 
    }
}

@media screen and (max-width: 834px) {
    #ai-button {
        animation-name: spin, moveRightToLeftTabletM;
        animation-fill-mode: forwards;
        bottom: 31px;
    }
    
    #ai-button .chatbot2 {
        width: 84px;
        height: 84px;
    }

    #ai-button .chatbot2 .outer-ring {
        width: 52px;
        height: 52px;
    }

    #ai-button:hover .chatbot2 .outer-ring.hover {
        width: 80px;
        height: 80px;
    }*

    #ai-button .chatbot2 .eyes {
        margin-top: -15px;
        margin-left: -14px;
    }
    #ai-button:hover .chatbot2 .eyes.hover {
        margin-top: -15px;
        margin-left: -14px;
    }
    #ai-button .chatbot2.sleep .eyes.asleep {
        margin-top: -16px;
        margin-left: -14px;
    }

    #ai-button .chatbot2 .eyes svg {
        width: 28px; 
        height: 28px; 
    }
}

@media screen and (max-width:414px) {
    #ai-button {
        animation-name: spin, moveRightToLeftMobileM;
        animation-fill-mode: forwards;
        bottom: 27px;
    }
    
    #ai-button .chatbot2 {
        width: 78px;
        height: 78px;
    }

    #ai-button .chatbot2 .outer-ring {
        width: 46px;
        height: 46px;
    }

    #ai-button:hover .chatbot2 .outer-ring.hover {
        width: 71px;
        height: 71px;
    }

    #ai-button .chatbot2 .eyes {
        margin-top: -13px;
        margin-left: -12px;
    }
    #ai-button:hover .chatbot2 .eyes.hover {
        margin-top: -13px;
        margin-left: -12px
    }
    #ai-button .chatbot2.sleep .eyes.asleep {
        margin-top: -13px;
        margin-left: -12px;
    }

    #ai-button .chatbot2 .eyes svg {
        width: 24px; 
        height: 24px; 
    }
}
@media screen and (max-width: 375px) {
    #ai-button {
        animation-name: spin, moveRightToLeftMobileS;
        animation-fill-mode: forwards;
        bottom: 27px;
    }
}