@import "colors";
@import "typography";
@import "buttons";
@import "dark-mode";

@import "header";
@import "accountmenu";
@import "cards";
@import "inputs";
@import "navigation";
@import "toggle";
@import "page";
@import "responsive";

@import "tabs";
@import "footer";
@import "accordion";
@import "carousel";

@import "skills";
@import "modal";
@import "toast";
@import "start";
// @import "email";
@import "map";
@import "chat";
@import "onboarding";

* {
    box-sizing: border-box;
}
[hidden] {
    display:none !important;
}
.icon-16 {
    width:16px;
    height:16px;
    fill:currentColor;
}
.icon-18 {
    width:18px;
    height:18px;
    fill:currentColor;
}
.icon-24 {
    width:24px;
    height:24px;
    fill:currentColor;
}


:root {
    --card-padding: 32px;
    --card-gap: 32px;
    --column-gap: 24px;
    --outside-padding: 16px;
    --vertical-space: 32px;
    --card-padding-skills: 4px;
    --card-padding-mini: 12px 20px;
}


@media (max-width: $breakpointMobile) {
    :root {
        --outside-padding: 16px;
        --card-padding-skills: 4px;
    }
}

@media (min-width: ($breakpointS + 1)) {
    :root {
        --outside-padding: 32px;
        --card-padding-skills: 8px;
    }
}
@media (min-width: ($breakpointM + 1)) {
    :root {
        --outside-padding: 64px;
    }
}