.scroll-carousel {
    position: relative;
    .carousel-nav {
        .slider-nav-button {
            position:absolute;
            z-index: 11;
            &:disabled {
                opacity: 0;
            }
            &.prev {
                left:-28px;
                top:50%;
                transform:translateY(calc(-50% - 14px));
            }
            &.next {
                right:-28px;
                top:50%;
                transform:translateY(calc(-50% - 14px));
            }
        }
    }
  
    .carousel-body{
        width:calc(100% + 32px);
        margin-right:-16px;
        margin-left:-16px;
        padding:0 16px 8px 16px;
        overflow-x:scroll;
        scroll-snap-type: x mandatory;
        overscroll-behavior-x: contain;
        display:flex;
        gap:8px;
        .carousel-tile {
            scroll-snap-align: center;
            // background-color: #122142;
            border-radius:16px;
            padding:24px;
            width:calc(100% - 0px);
            max-width:368px;
            flex:none;
            border:0;
            position: relative;
            
            .bookmark-btn {
                position:absolute;
                right:16px;
                top:16px;
                // color:var(--color-white);
            }
            .card-text, p, span {
                // color: var(--color-white);
            }
            a {margin-bottom:1em;text-decoration: none;}
            .company-name {
                display:flex;
                align-items: center;
                // color: var(--color-white);
            }
            .avatar {
                width:40px;
                height:40px;
                border:1px solid #fefefe;
                background-color: #60646D;
                margin-right:12px;
                display: inline-block;
                border-radius: 50%;
            }
            h4 {margin:1em 0;}

            h4 + p {margin-top:0; margin-bottom:0;}
            ul {
                list-style:none;
                margin:0;
                padding:0;
                p {margin:0;}
            }
            .card-footer {
                border-top:1px solid var(--color-border);
                padding-top:16px;
                margin-top:16px;

            }
        }

        .mini-card {
            // min-width: 309px;
            min-width:343px;
            max-width: 343px;
            // scroll-snap-align: center;
        }
    }

    .carousel-footer {
        margin-top:16px;
        .dots {
            display:flex;
            align-items: center;
            justify-content: center;
            gap:8px;

            .dot {
                display:block;
                width:6px;
                height:6px;
                // border:1px solid transparent;
                background-color:#60646D;
                border-radius:50%;
                margin:1px;

                &.active {
                    width:8px;
                height:8px;
                    background-color: var(--color-white);
                    margin:0;
                    // border-color:var(--color-white);
                }
            }
        }
    }
    @media (max-width:457px) {
        .carousel-body {
            .mini-card {
                max-width:100%;
                width:100%;
                min-width:100%;
            }
        }
    }

    @media (max-width:768px) {
        .carousel-tile {

        }
    }
    @media (min-width:769px) {
        .carousel-body {margin:0; gap:24px;width:100%;padding:0;}
        // .carousel-footer {display: none;}
    }
}

