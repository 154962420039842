.intro-container {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.5;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top:200px;
}

.bot-image {
    width: 100px;
    align-self: center;
}

.greeting-header {
    font-size: 48px;
    margin-bottom: 0;
    margin-top: 10px;
}

.info-container {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    line-height: 1.5;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    border-top: 1px solid #333;
    padding-bottom:100px;
}

.info-text {
    margin-top: 0;
}

.actions {
    display: flex;
    flex-direction: column;
}

.action-button {
    background: transparent;
    padding: 8px 24px;
    /* padding-left: 20px; */
    margin: 5px;
    border-radius: 30px;
    text-align: left;
    display: flex;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.25);
    background: rgba(0, 0, 0, 0.25);
    box-shadow: none;
    color:white;
    font-size: 1.125rem; 
    font-weight: 700;
    /* frosted-glass */
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
    backdrop-filter: blur(50px);
}
.action-button:hover {
    background: rgba(255, 255, 255, 0.25);
        color: #fefefe;
}

.icon-padding {
    padding-right: 10px;
}




.sidebar {
    padding:20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height:100%;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 75px;
    position: absolute;
    width:100%;
    backdrop-filter: blur(15px); /* optional blur effect */
    z-index: 100;
}
.sidebar-header > div {
    display: flex;
    align-items: center;
}
.sidebar-header > div > h2 {
    margin-left: 10px;
}
.sidebar-header img {
    width:50px;
}
.close-btn {
    background: none;
    border: none;
    color: #333;
    cursor: pointer !important;
    font-size: 1.2em;
    padding:10px;
}

.sidebar-content {
    /* Additional styling for the content inside the sidebar */
}

/* .button {
    background-color: #666; //Adjust the color to match your design 
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 20px; // Rounded corners for the button 
}

.button:hover {
    background-color: #555; // Slightly darker on hover
} */

.fade-out {
    animation: fadeOut 5s;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    0% { opacity: 1; }
    90% { opacity: 0; }
    100% {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
}

.level-up {
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(255, 255, 255, 0.10);
    padding:24px !important;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    margin-bottom:20px;
    position: relative;
}
.level-up span {
    display:flex;
    font-size:12px
}
.level-up img {
    max-width:100%;
    margin-bottom:24px;
    border-radius: 12px;
}
.level-title {
    font-size: 18px !important;
    font-weight: bold;
}

.chip-label.light {
      /* border:1px solid #ffffff40; */
      color: #020917;
      /* outline: 1px solid transparent; */
      background: #fefefe;
}
.chatbot-container .play-icon {
 display:flex;
    align-items: center;
    justify-content: center;
    width:259px;
    height:180px;
    position: absolute;
}