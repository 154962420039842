.toggle {
    button {
        font-size: 24px;
        position: relative;
        display: block;
        width: 64px;
        height: 32px;
        padding: 0;
        border: 0;
        border-radius: 32px;
        margin: 0;
        // color: #fff;
        background-color: var(--color-light-300);
        color: var(--color-dark-600);
        cursor: pointer;
        transition: background-color .3s;
        overflow: visible;
        min-width: 64px;
        &:before {
            content: "";
            background: var(--color-white);
            display: block;
            position: absolute;
            left: 2px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            -webkit-transition: left .3s;
            transition: left .3s;
            border-radius: 100%;
            z-index: 2;
        }

        .off {
            position:absolute;
            z-index: 3;
            top:50%;
            left:6px;
            transform: translateY(-50%);
            width:16px;
            height:16px;
            display: flex;
            transition: opacity 0.15s ease;
        }
        .on {
            position:absolute;
            z-index: 3;
            top:50%;
            right:6px;
            transform: translateY(-50%);
            width:16px;
            height:16px;
            display: flex;
            opacity: 0;
            transition: opacity 0.15s ease;
        }

        &[aria-checked=true] {
            &:before {
                left: calc(50% - 2px)
            }
            .off {
                opacity: 0;
            }
            .on {
                opacity: 1;
            }
        }

       
    }
    
}
.mode-toggle-container {
    display:flex;
    align-items: center;
    gap:16px;
    // max-width:152px;
    .mode-toggle svg {
        position:absolute;
        width:12px;
        height: 12px;
        z-index: 1;
        &.sun {
            top:50%;
            right:10px;
            transform: translateY(-50%);
        }
        &.moon {
            left:10px;
            top:50%;
            transform: translateY(-50%);
        }
    }

    .toggle-label {
        display:block;
        font-size:0.875rem;
        line-height:1.5rem;
        color: var(--text-muted);
        font-weight:400;
        &.right {
            text-align:left;
            min-width:50px;
        }
        &.left {
            text-align:right;
            min-width:50px;
        }
        &.selected {
            font-weight:600;
            color: var(--color-default);
        }
    }
}

.dark-mode {

    .toggle {
        button {
            background: var(--background-dark-theme-bg-level-1, #161C29);
            &:before {
                background-color: #393F4A;
            }
        }
    }
}