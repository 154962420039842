.header-wrapper {
    display:flex;
    align-items: center;
}
.page-header {
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding:0px var(--outside-padding) 0px var(--outside-padding);
    gap:24px;
    // margin-bottom: var(--vertical-space);
    // background:white;
    // background: linear-gradient(0deg, #0D1130, #0D1130), linear-gradient(354.67deg, #0E1031 44.51%, #350A4D 85.32%);
    // border-bottom:1px solid var(--bg-level2);
    height:69px; //70px with border

    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
background: rgba(0, 0, 0, 0.10);

/* frosted-glass */
box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
backdrop-filter: blur(50px);
    .logo {
        color: var(--color-white);
        a {color: var(--color-white);}
    }

   .tab-container .tab-slider {
        bottom:0;
    }
    .header-actions {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:16px;

        @media (min-width: 508px) {
            .button {
                white-space: nowrap;
            }
        }
        
        .button.outline {
            // color: var(--swatch-light-400, #D2D7E2);
        }
    }
    
    .account-menu-container {
        color:var(--color-white);
        .avatar {
            border-color:var(--color-white);
        }
        .icon-18 {
            color:inherit;
        }
    }
}