.horizontal-navigation {
    // position:fixed;
    //     top:0;
    //     left:0;
    //     right:0;
        z-index: 1111;
        width:100%;
        // background: linear-gradient(to right, #05112A, #3E0953);
        // // background-image: url('https://www.pearson.com/jobmatch/assets/waves.svg');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center top;
        // // overflow:hidden;
        // min-height: 70px;
        color: var(--color-white);


    .tab-container {
        // padding: 0 var(--outside-padding);
        .tab-list {
            overflow-x: auto;
            display:flex;
            margin-bottom:0;
        }
    }
    
}

