.seadragon-viewer {
    background-color: var(--color-dark-blue);
}
.map-container {
    position:relative;
    width:100%;
    height:100%;

    .toolbar {
        position:absolute;
        z-index: 11;
        top:86px;
        left:16px;
        // bottom: 24px;
        // left: 170px;
        display:flex;
        flex-direction:column-reverse;
        gap:12px;

        button {
            width:48px;
            height:48px;
            padding:12px;
            color:var(--color-white);
            background-color: rgba(254, 254, 254, 0.24);
            border-radius:50%;
            opacity: 0.8;

            &:hover, &:focus {
                background-color: rgba(254, 254, 254, 0.8);
                color:var(--text-default);
            }
        }
    }
}

.drawer-btn {
    // box-shadow: 0 2px 4px blue;
    background:transparent;
    border:none;
    position:relative;

    img {
        width:100%;
    }
    &:after {
        position:absolute;
        content:attr(aria-label);
        color: #fefefe;
        background-color:rgba(0,0,0,0.4);
        padding: 8px;
        font-size:0.875rem;
        font-weight:600;
        border-radius:8px;
        top:50%;
        transform:translate(-50%, -50%);
        left:50%;
        text-align: center;

       }
    
    &.recommended {
        filter: drop-shadow(7px 4px 16px #FE42FF) drop-shadow(-6px -6px 13.9px #FD38FF);
        img {
            // filter: drop-shadow(7px 4px 16px #FE42FF) drop-shadow(-6px -6px 13.9px #FD38FF);
        }
        &:after {
            color: var(--text-default);
            background-color:var(--color-white);
           }
    }
    &[data-rating=interested] {
        filter: drop-shadow(7px 4px 16px #FE42FF) drop-shadow(-6px -6px 13.9px #FD38FF);
        // // &:after {
        // //     background-color:#fefefe;
        // //     color:#020917;
        // }
        &.recommended {
            &:after {
                color: #fefefe;
                background-color:rgba(0,0,0,0.4);
            }
        }
    }
    &[data-rating=notinterested] {
        filter:none;
        &:after {
            background-color: #020917;
            color: #989BA3;
        }
    }
    

    &:hover {
    //    box-shadow: 0 2px 10px magenta; 
        img{
            filter: drop-shadow(0 2px 10px #FD38FF);
        }
        &:after {
            // position:absolute;
            // content:attr(aria-label);
            color: #2E3192;
            background-color: rgba(255,255,255,0.8);
            // padding: 4px 8px;
            // font-size:0.875rem;
            // font-weight:600;
            // border-radius:4px;
            // top:calc(100% - 8px);
            // left:0;
           }
    }
    //agriculture
    &.cluster1 {
        &:after {
            min-width: 120px;
            top:calc(100% - 0px);
        }
    }
    // architecture
    &.cluster2 {
        &:after {
            // width:100%;
            // left:-70%;
            // top:-25%;
            min-width: 110px;
            // top:calc(100% - 20px);
            // transfor/m: translateX(-50px);
        }
    }
    // finance
    &.cluster6 {
        &:after {
            // width:100%;
            // left:-50%;
            // top:calc(100% - 20px);
        }
    }
    
    //manufacturing
    &.cluster13 {
        &:after {
            // left:30%;
            // top:calc(100% + 0px);

        }
    }
    //business
    &.cluster4 {
        &:after {
            // width:120%;
            min-width:110px;
            // left:-50%;
        }
    }
    //stem
    &.cluster15 {
        &:after {
            min-width: 130px;
            // left:-25%;
            // top:calc(100% + 10px);
            // width:120%;
        }
    }
    //human services
    &.cluster17 {
        &:after {
            // top:cal/c(100% - 2px);
            // left:25%;
        }
    }
    //human education & training
    &.cluster18 {
        &:after {
            min-width: 110px;
            // top:calc(100% - 30px);
            // left:25%;
        }
    }
    //law
    &.cluster19 {
        &:after {
            min-width: 140px;
            // top:calc(100% - 30px);
            // transform:translateX(25%)
        }
    }
    //transportation 
    &.cluster16 {
        &:after {
            // top:calc(100% - 30px);
        }
    }
} 
