// body {
//     background: #F7F9FD;

// }
.dark-mode {
  background-color: var(--bg-level0);
    // background-color: var(--color-black);

    .card {
      background-color: var(--bg-level1);
      border: 1px solid var(--bg-level1);

      &.bg-image {
        border: 1px solid var(--bg-level1);
      }

      .card {
        background-color: var(--bg-level0);
        border: 1px solid var(--bg-level0);

        &.mini-card {
          p.meta {
            color: var(--text-muted);
          }
        }
        &.video-card {
          .card-subtitle {color: var(--text-muted);}
          p.card-meta {
            color: var(--text-default);
          }
        }
      }
    }
    .gr-input {
      background-color:transparent;
      border-color: var(--text-default);
    }
    /*buttons */
    .button {
        &.dark {
            &.filled {
                background-color: var(--swatch-light-400, #D2D7E2);
                color: var(--color-black, #020917);

              }
              &.outline {
                color: var(--text-dark-theme-default, #FEFEFE);
                outline: 2px solid var(--swatch-light-400, #D2D7E2);
                outline-offset:-2px;
                
              }
              &.clear {
                color: var(--text-dark-theme-default, #FEFEFE);
              }
             
              &:not(:disabled):hover {
                background: var(--swatch-light-100, #F7F9FD);
                color: var(--color-black, #020917);
                &.outline {
                  outline: 2px solid var(--swatch-light-100, #F7F9FD);
                }
              }
              &:not(:disabled):focus {
                background: var(--swatch-light-100, #F7F9FD);
                color: var(--color-black, #020917);
                box-shadow: 0px 0px 0px 2px var(--swatch-light-100, #F7F9FD);
                outline:0;
                &.outline {
                    outline: 2px solid var(--swatch-light-100, #F7F9FD);
                }
                
                &:after {
                  position:absolute;
                  content:'';
                  left:0px;
                  top:0px;
                  width:calc(100% - 4px);
                  height:calc(100% - 4px);
                  border: 2px dashed var(--border-dark-theme-border, #393F4A);
                  border-radius:50px;
                }
              }
        }
    }
    .button-link, .link-btn {
        color: var(--text-dark-theme-link, #9B81E2);
        &:hover {
        color: var(--text-dark-theme-link-hover, #B79BFF);
        }
    }
    .circle-btn {
      background-color:transparent;
      color:var(--text-default);
      // border-color:var(--text-default);
    }

    .career-switcher {
     .tab-list .tab-item {
        button {
          background: var(--bg-level0);
          border-color: var(--bg-level0)
        }
      }
    }
      
}