.modal-overlay{
    background:rgba(0,0,0,.45);
    height:100%;
    left:0;
    position:absolute; //to appear in the frame
    top:0;
    width:100%;
    z-index:1132;
    &.hide {
        display: none;
    }
    &.transparent {
        background:transparent;
        pointer-events: none;
        .modal {
            pointer-events: auto;
        }
    }
}

.modal {
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.25);    
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
    backdrop-filter: blur(50px);
    color: #fff;
    margin:0px auto;
    padding:32px;
    position:absolute;
    left:50%;
    top:50%;
    z-index:11;
    transform:translate(-50%, -50%);
    width:100%;
    max-height: calc(100vh - 40px);
    overflow-y: auto; //for now

    &.career-modal {
        left:0;
        top:70px;
        max-width:800px;
        width:calc(100% - 48px);
        margin:24px;
        transform:none;
        max-height: calc(100vh - 70px - 48px - 130px);
    }

    &.light-bg {
        background-color: var(--bg-level0, #fefefe);
    }
    .close-btn {
        position:absolute;
        right:24px;
        top:24px;
        color:#fff;
        background-color: transparent;
    }
    h1 {
        text-align:left;
        margin:0 0 8px 0;
    }
     h2, h3 {
        text-align:left;
        margin:1rem 0 0 0;
    }
    ul {
        margin:4px 0;
    }
   
    .image {
        display: flex;
        width: 200px;
        height:200px;
        padding: 16px;
        justify-content: center;
        align-items: center;
        // gap: 10px;
        border-radius: 8px;
        background: var(--transparent-light-24, rgba(254, 254, 254, 0.24));
        margin-bottom:16px;

        img {
            object-fit: contain;
            height: 100%;
        }
    }
    p, li, .desktop-h6 {
        margin:0;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .desktop-h6 {font-weight:700;}
    .modal-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px
    }
    .modal-body {
        // overflow-y: auto;

        .flex-row {
            gap:16px;
        }
        p + .desktop-h6 {margin-top:16px;}
        .benefits {
            p + .desktop-h6 {margin-top:24px;}
        }

        .right-col {
            display:flex;
            flex-direction: column;
            // .rating-row {margin-top:auto;}
        }
    }
    .course-description {
        padding-bottom:32px;
        border-bottom:1px solid var(--color-border);
        margin-bottom:32px;
        .avatar {
            flex:none;
            width:60px;
            height:60px;
            img {
                width:100%;
            }
        }
    }
    
    .desc-info {
        display:flex;
        flex-direction:row;
        align-items: flex-start;
        gap:12px;
    }

    .form-group + .form-group {
        margin-top:1rem;
    }

    
}
.chat-open .modal.career-modal {
    // left: calc(50% - 240px);
    
    width:calc(100vw - 469px - 48px - 16px);
    max-width:1200px;
    // width:calc(100vw - 240px);
}
.modal-header {
    padding-right:24px;
    margin-bottom:24px;
    &.border-bottom {
        padding-bottom:24px;
        border-bottom:1px solid var(--color-border);
        margin-bottom:16px;
        h1 {margin-bottom:0;}
    }
    h1 {
        &.text-left {
            text-align:left;
        }
    }
    
}

.modal-footer {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-top:48px;
}
.benefits {
    h2 {
        margin-bottom:0;
    }
    .icon {
        margin-right:8px;
    }
}
.benefits-row {
    display:flex;
    flex-direction: row;
    gap:24px;
    align-items: flex-start;
    width:100%;
    margin-top:16px;

    h3 {
        margin:0;
    }

    .icon {
        color:var(--text-dark-theme-link);
    }
}
.achievements-modal section {
    padding: 16px 0;
    margin-bottom:0;
}
section {
    margin-bottom:40px;
    &:last-of-type {
        margin-bottom:0;
    }
    h2 {
        .icon {
            vertical-align: middle;
            margin-right:12px;
            display:inline-block;
            width:31px;
            height:31px;
            img {width:100%;}
        }
    }
    .card-row {
        .card {
            min-width:240px;
            &.achievement-card {
                min-width:130px;
                height:auto;
            }
        }
    }

    .card.video-card .card-title {
        max-height:24px;
        text-overflow: ellipsis;
        overflow:hidden;
        -webkit-line-clamp:1;
    }
}

@media (min-width:768px){
    .modal{
        max-width:600px;
        
    }
    
}
@media (max-width:768px){
    .modal{max-width:440px;padding:24px}
}
@media (max-width:480px){
    .modal{
        width: calc(100% - 40px);
        // margin:20px;
    }
}