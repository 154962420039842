main.onboarding {
    // display: block;
    grid-template-columns: 1fr;
}
.onboarding-container {
    background: var(--purple-gradient, linear-gradient(216deg, #6D0176 -45.86%, rgba(109, 1, 118, 0.00) 55.93%), #05112A);
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    position: relative;

    &.with-character {
        &::after {
            content: "";
            height: calc(100% - 100px);
            width: 70%;
            background-image: url('../images/onboarding_character.png');
            background-size: 45vw;
            position: absolute;
            right: 0;
            bottom: 0;
            background-position: right bottom;
            background-repeat: no-repeat;
        }
    }
    .onboarding-content {
        max-width:760px;
        margin:20px;
        color:var(--color-white);
        z-index: 1;
        text-align:center;
        h1 {
            margin: 0 auto 8px auto;
            text-align:center;
            max-width:440px;
            width:100%;
        }
        p {
            margin:0 auto;
            width:100%;
            max-width:440px;
            font-size:1.125rem;
            line-height:1.3;
            text-align:center;
        }
        a {
            text-align:center;
            color:var(--text-dark-theme-link);
            font-size:1.125rem;
            font-weight:normal;
        }
        .assessment-reminder {
            margin: 60px auto;
        }

        .school-id {
            margin-top:48px;
            display:flex;
            flex-direction: row;
            align-items: flex-start;
            gap:24px;
            width:100%;
            color:var(--text-default);
            border-radius: 24px;
            background: linear-gradient(71deg, #DEEAFF 0.45%, #F1E8FF 99.05%);
            text-align: left;
            /* frosted-glass */
            box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset;
            backdrop-filter: blur(50px);
            .image {
                max-width:135px;
                img {
                    width:100%;
                    height:auto;
                }
            }
            h2, p, h3 {margin:0 0 4px 0;text-align: left;}
            
            .group {
                margin-top:12px;

            }
            .group-label {
                font-size: 14px;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
                letter-spacing: 0.24px;
            }
            p {
                font-size:1rem;
                font-weight: 500;
                line-height: 1.25;
                letter-spacing: 0.24px;
            }
            
        }

        .progress-dots {
            padding: 16px;
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content: center;
            gap:8px;
            width:100%;
            margin-bottom:8px;
            
            .dot {
                width:6px;
                height:6px;
                background-color:#989BA3;
                border-radius:50%;
                // border:2px solid transparent;
                &.filled {
                    background-color:#EA0B7E;
                    outline:1px solid #EA0B7E;
                }
            }
        }
        .chip-list {
            justify-content: center;
            margin-top:24px;
            margin-bottom:40px;

            .chip-btn.toggle-chip {
                font-size:1rem;
                margin:4px;
            }
        }
        .action-row {
            margin-top:16px;
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap:16px;

            button.outline {
                color:var(--color-white);
                outline-color:#FEFEFE40;
            
                &:not(:disabled):hover {
                    background: var(--color-white, #fefefe);
                    color: var(--swatch-dark-700, #393F4A);
                    &.outline {
                      outline: 2px solid var(--color-white, #fefefe);
                    }
                  }
            }
        }
        .bot-image {
            display: block;
            width: 140px;
            height: 140px;
            margin:auto;
            margin-bottom:24px;
            position:relative;
            
        }
    }
}



.bot-image {
    display: block;
    margin:auto;
    position:relative;
    img {
        width:100%;
    }
    .loading {
        display: block;
        width: 130px;
        height: 130px;
        position:absolute;
        left:2px;
        top:2px;
        
        &:after {
            content: " ";
            display: block;
            width: 104px;
            height: 104px;
            margin: 8px;
            border-radius: 50%;
            opacity: 0.5;
            mix-blend-mode: luminosity;
            filter: blur(2px);
            border: 8px solid currentColor;
            border-color: currentColor transparent transparent transparent;
            animation: lds-dual-ring 3s linear infinite;
        }
        @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        }


    }
}