.account-menu-container {
    position: relative;
    z-index: 11;
    .button-unstyled {
        position: relative;
        padding-right:18px; /*create space for arrow*/
        &[aria-expanded=true] {
            .avatar {
                background:var(--color-white);
                color:var(--text-default)
            }
            svg {
                transform: rotate(180deg);
            }
        }
    }
    .avatar {
        border: 2px solid var(--text-default);
        border-radius: 100px;
        padding-top: 0;
        height: 36px;
        width: 36px;
        display: block;
        line-height: 36px;
        font-weight: bold;
        overflow: hidden;
        img {
            width: auto;
            height: 110%;
        }
        
    }
    .icon-18 {
        position: absolute;
        top: 10px;
        right: 0px;
        color: var(--text-default);
        
        // @media (max-width: 1024px){
        //     color: var(--color-white);
        // }
        svg {
            fill: currentColor;
            transition:transform 0.15s ease;
        } 
    }
    .account-menu {
        display: block;
        position: absolute;
        background: var(--color-white);
        width: 200px;
        text-align: right;
        right: -13px;
        padding: 10px 0;
        top: 50px;
        border-radius: 5px;
        box-shadow: 2px 3px 8px 0px rgba(0,0,0,50%);
        color:var(--text-default);
        ul {list-style-type: none; margin:0;padding:0}
        a {
            display: block;
            padding: 6px 18px;
            text-align:left;
            // text-transform: capitalize;
            background:transparent;
            border:none;
            width:100%;
            color:var(--text-default);
            text-decoration:none;
            font-weight:normal;
            &:hover, &:focus {
              background: var(--bg-level2);
            }
        }

        li {
            //placeholder email alerts
            label {
                margin:4px 0 16px 0;
                font-size:0.825rem;
                display: block;
                text-align:left;
                padding-left: 24px;

                input {margin-right:8px;}
            }
        }
       
        &.hidden {
            display: none;
        }
    }
}