:root, .light-mode {
    --text-default: #020917;
    --text-muted: #60646D;
    --color-border: #DDE3EE;
    --bg-level0: #fefefe;
    --bg-level1: #F7F9FD;
    --bg-level2: #E8EEFA;
    
    --color-dark-blue: #05112A;
    --color-dark-purple: #6D0176;
    --color-medium-blue: #122142;
    --color-indigo: #1D3058;
    --grey-light1: #E0E7F0;

    --color-light-300: #DDE3EE;
    --color-dark-600: #4C515B;

    --color-plum: #6D0176;
    --color-success: #19a292;
    --color-success-500: #1F897C;
    --color-bright-teal: #0CC0AD;

    --text-light-theme-link: #655591;
    // --text-light-theme-link: rgba(109, 1, 118, 1);

    --text-dark-theme-link: #9B81E2;
    --border-radius: 16px;
    --card-corner-default: var(--border-radius);
    --color-white: #fefefe;
    --color-black: #020917;
    --gradient-01: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    --gradient-01-hover: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
    --checkbox-border: #60646D;
    --checkbox-checked: #1D3058B2;

    --column-gap: 24px;
    --color-dark-card: #122142;
    
   

}

.dark-mode {
    --bg-level0: #020917;
    --bg-level1: #161C29;
    --text-default: #fefefe;
    --text-muted: #A7BAD0;

    --color-border: #393F4A;
    .card {
        
    }
}


